import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { FaEnvelope, FaPaperPlane } from "react-icons/fa"

import "../scss/youmedico.scss"

import BannerImg from "../assets/img/youMedico/banner.png"
import Rectangle from "../assets/img/youMedico/rectangle.svg"
import DashboardIcon from "../assets/img/youMedico/telemedicine/DashboardIcon.svg"
import HipaaIcon from "../assets/img/youMedico/telemedicine/HipaaIcon.svg"
import SecureIcon from "../assets/img/youMedico/telemedicine/SecureIcon.svg"
import CloudIcon from "../assets/img/youMedico/telemedicine/CloudIcon.svg"
import NeutronIcon from "../assets/img/youMedico/telemedicine/NeutronIcon.svg"
import LikeIcon from "../assets/img/youMedico/telemedicine/LikeIcon.svg"
import Img1 from "../assets/img/youMedico/Img1.png"
import Img2 from "../assets/img/youMedico/Img2.png"
import Img3 from "../assets/img/youMedico/Img3.png"
import appendScript from '../utils/appendScript';

const HomePage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=31", "contact-us-mautic-internal-form");

  return (
    <div>
      <SEO
        title="Youmedico"
        keywords={[`youpal`, `youpal group`, `youMedico`]}
      />
      <div className="youMedicoMain">
        <section className="container mb-5 internalSection">
          <div className="sectionSeparation row TwoCol mainBackgroundImage">
            <div className="col d-lg-none">
              <img src={BannerImg} className="sideImg" />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h2 className="text-left mainTitle">
                  Medicine Redefined With a Swedish Touch
                </h2>
                <p>
                We simplify medical processes through our integrated SaaS solutions. Our unique software options are customisable and accessible via websites and mobile apps.
                </p>

                <div className="buttonArea d-none">
                  <Link to="/services/">
                    <button
                      type="button"
                      className="px-5 text-center btn btn-primary"
                    >
                      <span className="mr-0">Read More</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </section>

        <section className="container">
          <div className="col">
            <img src={Rectangle} />
            <h3 className="mb-5 internalNarrowCol">Our Solutions</h3>
            <p className="internalNarrowCol sectionSeparation">
            Many patients are unable to regularly visit a specialist. Our software bridges the gap between professional medical care and patients, no matter where they are in the world.
            </p>
          </div>
        </section>

        <section className="container internalSection">
          <div className="mb-5 row TwoCol sectionSeparation">
            <div className="col">
              <img className="sideImg" src={Img1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-left">Telemedicine</h4>
                <p>
                Opting for Telemedicine will help you save time with
                </p>
                <p className="mt-5">
                  <ul>
                    <li>Timely follow-ups</li>
                    <li>Consultation via messaging</li>
                    <li>Having access to the patient’s medical history on the software</li>
                    <li>Alerts for upcoming sessions </li>
                    <li>Managing schedule</li>
                  </ul>
                </p>
                <p className="mt-5">
                This will help you improve patient outcome and increase revenue.  Our apps are available on Android, iOs and the web.
                </p>
                <p className="mt-5">
                Our software also ensures payments are processed in a secure manner.
                </p>

                <div className="buttonArea">
                  <Link to="/services/">
                    <button
                      type="button"
                      className="px-5 text-center btn btn-primary"
                    >
                      <span className="mr-0">Learn More</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 row TwoCol sectionSeparation TwoColAlternate">
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-left">
                Remote Patient/Health Monitoring (IoT)
                </h4>
                <p>
                We have designed RPM using the latest technology. Our RPM solutions efficiently detect anomalies and chronic diseases while lowering the diagnosis and treatment expenses.
                </p>

                <div className="buttonArea">
                  <Link to="/services/">
                    <button
                      type="button"
                      className="px-5 text-center btn btn-primary"
                    >
                      <span className="mr-0">Learn More</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={Img2} />
            </div>
          </div>
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={Img3} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-left">Global Medical Staff Database</h4>
                <p>
                Our Global Medical Staff Database is a digital healthcare sourcing platform which has the information of hundreds of medical specialists. This is available to both healthcare providers and patients.
                </p>
                <div className="buttonArea">
                  <Link to="/services/">
                    <button
                      type="button"
                      className="px-5 text-center btn btn-primary"
                    >
                      <span className="mr-0">Learn More</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <img src={Rectangle} />
              <h3 className="mb-5 internalNarrowCol">Why Us?</h3>
              <p className="internalNarrowCol sectionSeparation">
              Youmedico is part of the Youpal Group. We combine the best of technology with ethical practices, so our business benefits everyone positively.
              </p>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={HipaaIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>HIPAA compliant</h4>
                      <p>
                      Data security and product safety is our top priority and you can trust Youmedico to be compliant with all rules.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={SecureIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Secure and fast</h4>
                      <p>
                      Our app is automatically synchronised. You don’t have to wait in queue or even see the loading symbol on your screen.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={DashboardIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Powerful dashboard</h4>
                      <p>
                      Our organised dashboard makes it easy to navigate through the website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={CloudIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Cloud</h4>
                      <p>
                      Youmedico’s cloud database collects and saves patient information to help faster and better medical advice.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={NeutronIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Custom to fit</h4>
                      <p>
                      Customise Youmedico to fit your requirements and needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={LikeIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Custom Domain</h4>
                      <p>
                      Youmedico also provides custom domains to make each patient unique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="contactBox">
              <h2>Request a demo</h2>
              <div className="sendLinkContainer">
                <div className="formDiv">
                    <FaEnvelope size={25} />
                    {/* Mautic form */}
                    <div id='contact-us-mautic-internal-form'/>
                </div>
              </div>
            </div>
        </section>
      </div>
    </div>
  )
}

export default HomePage
